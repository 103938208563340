import { AxiosError } from "axios";
import jwtDecode from "jwt-decode";
import moment from 'moment';
import { toast } from "react-toastify";
import { accents, accentsOut, apiUrl, environment } from "./constants";
import { IToken } from "./interfaces";
import { IListDocs } from "./transparencia-difusion/interfaces";

export const handleAxiosError = (error: AxiosError) => {
  console.log(error)
  if (!error || !error.response) return toast.error('Ha ocurrido un error inesperado. Intente más tarde.');

  const status = error.response.status;
  const data = error.response?.data;

  if (status === 404 && !data!.errors) toast.error(data);
  if (status === 404 && data?.errors.length > 0) data.errors.map(error => toast.error(error.msg));

  if (status === 503 || status === 504) return toast.error('El servicio no se encuentra disponible. Por favor, intente más tarde.');
  if (status >= 500) return toast.error('Hubo un problema al realizar la petición al servidor. Intente más tarde.');

  if (status === 401) {
    if (checkJwtExpiration()) return;
    return toast.error(data);
  }

  if (status >= 400) return toast.error('La petición que solicitó no pudo ser concluida. Recargue e intente de nuevo.');
};

export const getApiUrl = (api: 'sipem' | 'portal') => {
  // const IS_EXTERNO = typeof window !== 'undefined' && window.location.hostname && window.location.hostname === 'www.isset.gob.mx';

  // if (environment === 'production') {
  //   switch (api) {
  //     case 'sipem': return !IS_EXTERNO ? apiUrl.production.sipemInterno : apiUrl.production.sipemExterno;
  //     case 'portal': return !IS_EXTERNO ? apiUrl.production.portalInterno : apiUrl.production.portalExterno;
  //     default: return '';
  //   }
  // }

  switch (api) {
    case 'sipem': return apiUrl[environment].sipem;
    case 'portal': return apiUrl[environment].portal;
    default: return '';
  }

}

export const encodeQueryData = (data: any) => {
  const ret = [];
  for (let d in data) {
    if (data[d] !== '0' && data[d] !== '' && data[d] !== 0 && data[d] !== null && data[d] !== undefined) {
      ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
    }
  }
  return ret.join('&');
}

export const toCapitalize = (str: string) => str.charAt(0).toUpperCase() + str.substr(1, str.length).toLowerCase();

export const titleCase = (str: string) => {
  const words = str.toLowerCase().split(' ');
  for (let i = 0; i < words.length; i++) words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
  return words.join(' ');
}

export const randomNumber = (max: number, min: number = 0) => Math.floor(Math.random() * (max - min + 1)) + min;

export const sanitizeHtml = (str: string) => str.replace(/&lt;/g, '<').replace(/&gt;/g, '>');

export const removeItemOnce = (array: any[], value: any) => {
  const index = array.indexOf(value);
  if (index > -1) array.splice(index, 1);
}

export const flatten = (array: any[]) => {
  var flattend = [];
  !(function flat(array) {
    array.forEach(function (el) {
      if (Array.isArray(el)) flat(el);
      else flattend.push(el);
    });
    return true;
  })(array);
  return flattend;
}

export const enumerateDaysBetweenDates = function (startDate: Date, endDate: Date, excludes: Date[] = [], params: Partial<{
  excludeFirst: boolean,
  excludeLast: boolean
}> = {}): Date[] {
  const dates: Date[] = [];
  const currDate = moment(startDate).startOf('day');
  const lastDate = moment(endDate).startOf('day');

  const isExcludeFirst = excludes.some(exclude => moment(exclude).isSame(currDate));
  const isExcludeLast = excludes.some(exclude => moment(exclude).isSame(lastDate));

  if (!params?.excludeFirst && !isExcludeFirst) dates.push(currDate.toDate());

  while (currDate.add(1, 'days').diff(lastDate) < 0) {
    if (!excludes.some(exclude => moment(exclude).isSame(currDate))) {
      if (currDate.day() !== 6 && currDate.day() !== 0) {
        dates.push(currDate.clone().toDate());
      }
    }
  }

  if (!params?.excludeLast && currDate.isSame(lastDate) && !isExcludeLast) dates.push(lastDate.toDate());

  return dates;
};

export const checkJwtExpiration = () => {
  const token = sessionStorage.getItem('token');

  if (!token) return false;

  const decoded: IToken = jwtDecode(token);
  if (decoded.exp < (Date.now() / 1000)) {
    sessionStorage.clear();
    return false;
  }

  return true;
}

export const removeAccents = (str: string) => {
  const strArray = str.split('');
  for (let i = 0; i < strArray.length; i++) {
    const x = accents.indexOf(str[i]);
    if (x != -1) strArray[i] = accentsOut[x];
  }
  return strArray.join('');
}

export const searchByString = (elements:string[], arrayList: IListDocs[]): IListDocs | undefined=> {
  let objectFound: IListDocs | null = null;
  let elementos: IListDocs[] = arrayList;
  

  for (let i = 0; i < elements.length; i++) {
    const propiedad: string = elements[i];

    objectFound = elementos.find((elemento) => elemento.name === propiedad) || null;
    

    if (!objectFound) {
      break;
    }

    elementos = objectFound.elements || [];
  }
  
  if(objectFound){
    return objectFound;
  } else {
    return undefined;
  }
}