import React from "react";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import { CustomArrowProps } from "react-slick";

export const SlickArrowLeft = ({ currentSlide, slideCount, ...props }: CustomArrowProps) => (
  <button
    {...props}
    className={
      "slick-prev slick-arrow" +
      (currentSlide === 0 ? " slick-disabled" : "")
    }
    aria-hidden="true"
    aria-disabled={currentSlide === 0 ? true : false}
    type="button"
  >
    <ChevronLeft />
  </button>
);

export const SlickArrowRight = ({ currentSlide, slideCount, ...props }: CustomArrowProps) => (
  <button
    {...props}
    className={
      "slick-next slick-arrow" +
      (currentSlide === slideCount - 1 ? " slick-disabled" : "")
    }
    aria-hidden="true"
    aria-disabled={currentSlide === slideCount - 1 ? true : false}
    type="button"
  >
    <ChevronRight />
  </button>
);