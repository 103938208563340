import React from 'react';
import { ThemeProvider, CssBaseline } from '@material-ui/core';
import theme from './src/utils/theme';

export default ({ element }) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {element}
    </ThemeProvider>
  );
};