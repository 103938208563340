import { CSSProperties } from '@material-ui/styles';
import axios from 'axios';
import React from 'react';
import { Settings as SlickSettings } from 'react-slick';
import { SlickArrowLeft, SlickArrowRight } from '../components/templates/CustomArrows';
import { getApiUrl } from './functions';
import { ITramite } from './interfaces';

export const environment: 'development' | 'test' | 'production' = 'production';

export const escala_gris: boolean = false;
export const veda_electoral: boolean = false;
export const disable_propaganda: boolean = false;

export const apiUrl = {
  development: {
    sipem: '',
    portal: 'http://localhost:6009',
  },
  test: {
    sipem: '',
    portal: 'http://192.168.6.181',
  },
  production: {
    sipem: '',
    portal: 'https://www.isset.gob.mx'
  }
}

export const axiosPortal = axios.create({
  baseURL: `${getApiUrl('portal')}/api`,
  validateStatus: status => status >= 200 && status <= 305,
  headers: {
    'Content-Type': 'application/json'
  }
});

export const accents = 'ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüŠšŸÿýŽž';
export const accentsOut = "AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuSsYyyZz";

export const containerProps: CSSProperties = {
  maxWidth: 1300,
  margin: '0 auto',
  marginTop: 70,
  marginBottom: 70,
  padding: '0 20px'
}

export const contentProps: CSSProperties = {
  marginTop: 32
}

export const defaultSliderSettings: SlickSettings = {
  dots: true,
  infinite: true,
  speed: 600,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  autoplay: true,
  variableWidth: false,
  pauseOnDotsHover: true,
  nextArrow: <SlickArrowRight />,
  prevArrow: <SlickArrowLeft />
};

export const infraestructuras: { name: string, to: string }[] = [
  {
    name: 'Centro Regional Centla',
    to: require('../assets/images/gallery/infraestructura/0.jpg')
  },
  {
    name: 'Centro Administrativo de Pestraciones ISSET',
    to: require('../assets/images/gallery/infraestructura/1.jpg')
  },
  {
    name: 'Centro Regional Cárdenas',
    to: require('../assets/images/gallery/infraestructura/2.jpg')
  },
  {
    name: 'Centro de Especialidades Médicas',
    to: require('../assets/images/gallery/infraestructura/3.jpg')
  },
  {
    name: 'ISSET CAI 1',
    to: require('../assets/images/gallery/infraestructura/4.jpg')
  },
  {
    name: 'ISSET CAI 2',
    to: require('../assets/images/gallery/infraestructura/5.jpg')
  },
  {
    name: 'ISSET CAI IV',
    to: require('../assets/images/gallery/infraestructura/6.jpg')
  },
  {
    name: 'ISSET CAI IV',
    to: require('../assets/images/gallery/infraestructura/7.jpg')
  },
  {
    name: 'Centro Cultural "Casa Aurora"',
    to: require('../assets/images/gallery/infraestructura/8.jpg')
  },
  {
    name: 'Finanzas ISSET',
    to: require('../assets/images/gallery/infraestructura/9.jpg')
  },
  {
    name: 'Centro Médico "Dr. Juan Puig Palacios"',
    to: require('../assets/images/gallery/infraestructura/10.jpg')
  },
  {
    name: 'Funeraria ISSET',
    to: require('../assets/images/gallery/infraestructura/11.jpg')
  },

  {
    name: 'Unidad de Hemodialisis',
    to: require('../assets/images/gallery/infraestructura/13.jpg')
  },
  {
    name: 'CECUIDAM',
    to: require('../assets/images/gallery/infraestructura/14.jpg')
  },
  {
    name: 'Archivos ISSET',
    to: require('../assets/images/gallery/infraestructura/15.jpg')
  },
  {
    name: 'Carpa ISSET',
    to: require('../assets/images/gallery/infraestructura/16.jpg')
  },
  {
    name: 'Residencia Médica ISSET',
    to: require('../assets/images/gallery/infraestructura/17.jpg')
  },
  {
    name: 'UMF Tamulte de las Sabanas',
    to: require('../assets/images/gallery/infraestructura/18.jpg')
  },

  {
    name: 'UMF Balancan',
    to: require('../assets/images/gallery/infraestructura/19.jpg')
  },
  {
    name: 'UMF Benito Juárez',
    to: require('../assets/images/gallery/infraestructura/20.jpg')
  },
  {
    name: 'Centro Regional de Comalcalco',
    to: require('../assets/images/gallery/infraestructura/21.jpg')
  },
  {
    name: 'Cunduacan',
    to: require('../assets/images/gallery/infraestructura/22.jpg')
  },
  {
    name: 'Huimanguillo',
    to: require('../assets/images/gallery/infraestructura/23.jpg')
  },
  {
    name: 'Centro Regional Jalapa',
    to: require('../assets/images/gallery/infraestructura/24.jpg')
  },
  {
    name: 'Jalpa de Mendez',
    to: require('../assets/images/gallery/infraestructura/25.jpg')
  },
  {
    name: 'Jonuta',
    to: require('../assets/images/gallery/infraestructura/26.jpg')
  },
  {
    name: 'La Venta Huimanguillo',
    to: require('../assets/images/gallery/infraestructura/27.jpg')
  },
  {
    name: 'Macuspana',
    to: require('../assets/images/gallery/infraestructura/28.jpg')
  },
  {
    name: 'Nacajuca',
    to: require('../assets/images/gallery/infraestructura/29.jpg')
  },
  {
    name: 'Paraiso',
    to: require('../assets/images/gallery/infraestructura/30.jpg')
  },
  {
    name: 'Sanchez Magallanes',
    to: require('../assets/images/gallery/infraestructura/31.jpg')
  },
  {
    name: 'Tacotalpa',
    to: require('../assets/images/gallery/infraestructura/32.jpg')
  },
  {
    name: 'Tamulté de las Sabanas',
    to: require('../assets/images/gallery/infraestructura/33.jpg')
  },
  {
    name: 'Tapijulapa',
    to: require('../assets/images/gallery/infraestructura/34.jpg')
  },
  {
    name: 'Teapa',
    to: require('../assets/images/gallery/infraestructura/35.jpg')
  },
  {
    name: 'Tenosique',
    to: require('../assets/images/gallery/infraestructura/36.jpg')
  },
  {
    name: 'Villa el Triunfo',
    to: require('../assets/images/gallery/infraestructura/37.jpg')
  },
  {
    name: 'Villa la Venta Huimangullo',
    to: require('../assets/images/gallery/infraestructura/38.jpg')
  },
  {
    name: 'Villa Vicente Guerrero',
    to: require('../assets/images/gallery/infraestructura/39.jpg')
  },
  {
    name: 'Zapata',
    to: require('../assets/images/gallery/infraestructura/40.jpg')
  },
];

export const recorridos: { name: string, to: string }[] = [
  {
    name: 'Reccorido 1',
    to: require('../assets/images/gallery/recorrido-gobernador/1.jpg')
  },
  {
    name: 'Reccorido 2',
    to: require('../assets/images/gallery/recorrido-gobernador/2.jpg')
  },
  {
    name: 'Reccorido 3',
    to: require('../assets/images/gallery/recorrido-gobernador/3.jpg')
  },
  {
    name: 'Reccorido 4',
    to: require('../assets/images/gallery/recorrido-gobernador/4.jpg')
  },
  {
    name: 'Reccorido 5',
    to: require('../assets/images/gallery/recorrido-gobernador/5.jpg')
  },
  {
    name: 'Reccorido 6',
    to: require('../assets/images/gallery/recorrido-gobernador/6.jpg')
  },
  {
    name: 'Reccorido 7',
    to: require('../assets/images/gallery/recorrido-gobernador/7.jpg')
  },
  {
    name: 'Reccorido 8',
    to: require('../assets/images/gallery/recorrido-gobernador/8.jpg')
  },
  {
    name: 'Reccorido 9',
    to: require('../assets/images/gallery/recorrido-gobernador/9.jpg')
  },
  {
    name: 'Reccorido 10',
    to: require('../assets/images/gallery/recorrido-gobernador/10.jpg')
  },
  {
    name: 'Reccorido 11',
    to: require('../assets/images/gallery/recorrido-gobernador/11.jpg')
  }
];

export const equipamientos: { name: string, to: string }[] = [
  {
    name: 'Equipamiento 1',
    to: require('../assets/images/gallery/equipamiento-medico/1.jpg')
  },
  {
    name: 'Equipamiento 2',
    to: require('../assets/images/gallery/equipamiento-medico/2.jpg')
  },
  {
    name: 'Equipamiento 3',
    to: require('../assets/images/gallery/equipamiento-medico/3.jpg')
  },
  {
    name: 'Equipamiento 4',
    to: require('../assets/images/gallery/equipamiento-medico/4.jpg')
  },
  {
    name: 'Equipamiento 5',
    to: require('../assets/images/gallery/equipamiento-medico/5.jpg')
  },
  {
    name: 'Equipamiento 6',
    to: require('../assets/images/gallery/equipamiento-medico/6.jpg')
  },
  {
    name: 'Equipamiento 7',
    to: require('../assets/images/gallery/equipamiento-medico/7.jpg')
  },
  {
    name: 'Equipamiento 8',
    to: require('../assets/images/gallery/equipamiento-medico/8.jpg')
  },
  {
    name: 'Equipamiento 9',
    to: require('../assets/images/gallery/equipamiento-medico/9.jpg')
  },
  {
    name: 'Equipamiento 10',
    to: require('../assets/images/gallery/equipamiento-medico/10.jpg')
  },
  {
    name: 'Equipamiento 11',
    to: require('../assets/images/gallery/equipamiento-medico/11.jpg')
  },
  {
    name: 'Equipamiento 12',
    to: require('../assets/images/gallery/equipamiento-medico/12.jpg')
  }
];

export const tramites: ITramite[] = [
  {
    name: 'Citología en la Unidad de Medicina Familiar de Centro (UMF-Centro, ISSET).',
    level: [1],
    to: 'https://tabasco.gob.mx/tramite/citologia'
  },
  {
    name: "Laboratorio de análisis clínicos en la Unidad de Medicina Familiar de Centro (UMF-Centro, ISSET).",
    level: [1],
    to: "https://tabasco.gob.mx/tramite/solicitud-de-atencion-para-laboratorio-clinico-en-la-unidad-de-medicina-familiar-de-centro"
  },
  {
    name: "Rehabilitación en la Unidad de Medicina Familiar de Centro (UMF-Centro, ISSET).",
    level: [1],
    to: "https://tabasco.gob.mx/tramite/solicitud-de-atencion-para-rehabilitacion-en-la-unidad-de-medicina-familiar-de-centro"
  },
  {
    name: "Nutrición en la Unidad de Medicina Familiar de Centro (UMF-Centro, ISSET).",
    level: [1],
    to: "https://tabasco.gob.mx/tramite/solicitud-de-atencion-para-nutricion-en-la-unidad-de-medicina-familiar-de-centro"
  },
  {
    name: "Odontología en la Unidad de Medicina Familiar de Centro (UMF-Centro, ISSET).",
    level: [1],
    to: "https://tabasco.gob.mx/tramite/solicitud-de-atencion-para-odontologia-en-la-unidad-de-medicina-familiar-de-centro"
  },
  {
    name: "Consulta general en la Unidad de Medicina Familiar de Centro (UMF-Centro, ISSET).",
    level: [1],
    to: "https://tabasco.gob.mx/tramite/solicitud-de-consulta-familiar-en-la-unidad-de-medicina-familiar-de-centro"
  },
  {
    name: "Consulta general en las unidades de medicina familiar del Centro Regional Comalcalco que incluye las unidades de: Comalcalco, Cunduacán, Jalpa de Méndez y Nacajuca (ISSET).",
    level: [1],
    to: "https://tabasco.gob.mx/tramite/solicitud-de-consulta-familiar-en-las-unidades-de-medicina-familiar-region-chontalpa-chica"
  },
  {
    name: "Consulta general en las unidades de medicina familiar del Centro Regional Jalapa que incluye las unidades de: 9.Jalapa, Macuspana; Villa Benito Juárez, Tacotalpa; Villa Tapijulapa y Teapa (ISSET).",
    level: [1],
    to: "https://tabasco.gob.mx/tramite/solicitud-de-consulta-familiar-en-las-unidades-de-medicina-familiar-region-de-la-sierra"
  },
  {
    name: "Consulta general en unidades de medicina familiar del Centro Regional Cárdenas, que incluye las unidades de: Cárdenas; Villa Sánchez Magallanes, Huimanguillo y Villa la Venta (ISSET).",
    level: [1],
    to: "https://tabasco.gob.mx/tramite/solicitud-de-consulta-familiar-en-unidades-de-medicina-familiar-region-chontalpa-grande"
  },
  {
    name: "Consulta general en unidades de medicina familiar del Centro Regional Centla, que incluye las unidades de: Centla; Villa Vicente Guerrero y Paraíso (ISSET).",
    level: [1],
    to: "https://tabasco.gob.mx/tramite/solicitud-de-consulta-familiar-en-unidades-de-medicina-familiar-region-de-la-costa"
  },
  {
    name: "Consulta general en unidades de medicina familiar del Centro Regional Emiliano Zapata, que incluye las unidades de: Balancán; Villa el Triunfo, Emiliano Zapata, Jonuta y Tenosique (ISSET).",
    level: [1],
    to: "https://tabasco.gob.mx/tramite/solicitud-de-consulta-familiar-en-unidades-de-medicina-familiar-region-de-los-rios"
  },
  {
    name: "Surtimiento de medicamentos en farmacia del Centro Regional Comalcalco, que incluye las unidades de: Comalcalco, Cunduacán, Jalpa de Méndez y Nacajuca (ISSET).",
    level: [1],
    to: "https://tabasco.gob.mx/tramite/solicitud-de-surtimiento-de-medicamentos-en-farmacia-de-la-region-chontalpa-chica"
  },
  {
    name: "Surtimiento de medicamentos en farmacia del Centro Regional Emiliano Zapata que incluye las unidades de: Balancán; Villa el Triunfo, Emiliano Zapata, Jonuta y Tenosique (ISSET).",
    level: [1],
    to: "https://tabasco.gob.mx/tramite/solicitud-de-surtimiento-de-medicamentos-en-farmacia-de-la-region-de-los-rios"
  },
  {
    name: "Surtimiento de medicamentos en farmacia de la Unidad de Medicina Familiar Centro (UMF-Centro, ISSET)",
    level: [1],
    to: "https://tabasco.gob.mx/tramite/solicitud-de-surtimiento-de-medicamentos-en-farmacia-de-unidad-de-medicina-familiar-centro"
  },
  {
    name: "Surtimiento de medicamentos en farmacia del Centro Regional Cárdenas, que incluye las unidades de: Cárdenas; Villa Sánchez Magallanes, Huimanguillo y Villa la Venta (ISSET).",
    level: [1],
    to: "https://tabasco.gob.mx/tramite/solicitud-de-surtimiento-de-medicamentos-en-farmacia-region-chontalpa-grande"
  },
  {
    name: "Surtimiento de medicamentos en farmacia del Centro Regional Centla, que incluye las unidades de: Centla; Villa Vicente Guerrero y Paraíso (ISSET).",
    level: [1],
    to: "https://tabasco.gob.mx/tramite/solicitud-de-surtimiento-de-medicamentos-en-farmacia-region-de-la-costa"
  },
  {
    name: "Surtimiento de medicamentos en farmacia del Centro de Regional Jalapa que incluye las unidades de: Jalapa, Macuspana; Villa Benito Juárez, Tacotalpa; Villa Tapijulapa y Teapa (ISSET).",
    level: [1],
    to: "https://tabasco.gob.mx/tramite/solicitud-de-surtimiento-de-medicamentos-en-farmacia-region-de-la-sierra"
  },
  {
    name: "Psicología clínica en la Unidad de Medicina Familiar de Centro (UMF-Centro, ISSET).",
    level: [1],
    to: "https://tabasco.gob.mx/tramite/solicitud-para-atencion-de-psicologia-clinica-en-la-unidad-de-medicina-familiar-de-centro"
  },
  {
    name: "Vacunas en la Unidad de Medicina Familiar de Centro (UMF-Centro, ISSET).",
    level: [1],
    to: "https://tabasco.gob.mx/tramite/vacunas"
  },
  {
    name: "Consulta de cirugía general en el Centro Regional Cárdenas (ISSET).",
    level: [2, 3],
    to: "https://tabasco.gob.mx/tramite/consulta-externa-de-cirugia-general-en-la-region-de-la-chontalpa-grande"
  },
  {
    name: "Hemodiálisis en el Centro Regional Emiliano Zapata (ISSET).",
    level: [2, 3],
    to: "https://tabasco.gob.mx/tramite/consulta-externa-de-gineco-obstetricia-en-la-region-de-la-chontalpa-grande"
  },
  {
    name: "Consulta de medicina interna en el Centro Regional Comalcalco (ISSET).",
    level: [2, 3],
    to: "https://tabasco.gob.mx/tramite/consulta-externa-de-medicina-interna-en-la-region-de-la-chontalpa-chica"
  },
  {
    name: "Consulta de medicina interna en el Centro Regional Cárdenas (ISSET).",
    level: [2, 3],
    to: "https://tabasco.gob.mx/tramite/consulta-externa-de-medicina-interna-en-la-region-de-la-chontalpa-grande"
  },
  {
    name: "Consulta de pediatría en el Centro Regional Comalcalco (ISSET).",
    level: [2, 3],
    to: "https://tabasco.gob.mx/tramite/consulta-externa-de-pediatria-en-la-region-de-la-chontalpa-chica"
  },
  {
    name: "Consulta de pediatría en el Centro Regional Cárdenas (ISSET).",
    level: [2, 3],
    to: "https://tabasco.gob.mx/tramite/consulta-externa-de-pediatria-en-la-region-de-la-chontalpa-grande"
  },
  {
    name: "Imagenología en el Centro de Especialidades Médicas \"Dr. Julián A. Manzur Ocaña\" (CEM-ISSET).",
    level: [2, 3],
    to: "https://tabasco.gob.mx/tramite/solicitud-de-atencion-para-imagenologia-en-el-centro-de-especialidades-medicas"
  },
  {
    name: "Hemodiálisis en el Centro Regional Comalcalco (ISSET).",
    level: [2, 3],
    to: "https://tabasco.gob.mx/tramite/solicitud-de-atencion-para-la-unidad-de-hemodialisis-en-la-chontalpa-chica"
  },
  {
    name: "Hemodiálisis en el Centro Regional Cárdenas (ISSET).",
    level: [2, 3],
    to: "https://tabasco.gob.mx/tramite/hemodialisis-chontalpa-grande-isset"
  },
  {
    name: "Consulta de especialidad en el Centro de Especialidades Médicas \"Dr. Julián A. Manzur Ocaña\" (CEM-ISSET).",
    level: [2, 3],
    to: "https://tabasco.gob.mx/tramite/solicitud-de-consulta-para-especialidad-en-el-centro-de-especialidades-medicas"
  },
  {
    name: "Surtimiento de medicamentos en farmacia del Centro de Especialidades Médicas \"Dr. Julián A. Manzur Ocaña\" (CEM-ISSET).",
    level: [2, 3],
    to: "https://tabasco.gob.mx/tramite/solicitud-de-medicamentos-en-el-centro-de-especialidades-medicas"
  },
  {
    name: "Laboratorio de análisis clínicos en el Centro de Especialidades Médicas \"Dr. Julián A. Manzur Ocaña\" (CEM-ISSET).",
    level: [2, 3],
    to: "https://tabasco.gob.mx/tramite/solicitud-de-atencion-para-laboratorio-clinico-en-el-centro-de-especialidades-medicas"
  },
  {
    name: "Urgencias en el Centro de Especialidades Médicas \"Dr. Julián A. Manzur Ocaña\" (CEM-ISSET).",
    level: [2, 3],
    to: "https://tabasco.gob.mx/tramite/urgencias-en-el-centro-de-especialidades-medicas"
  }
];

export const comisioncentralmixta: { name: string, to: string }[] = [
  {
    name: 'Imagen',
    to: require('../assets/images/gallery/comision-central-mixta/1.jpg')
  }
];

export const comiteinternomejora: { name: string, to: string }[] = [
  {
    name: 'Imagen',
    to: require('../assets/images/gallery/comite-interno-mejora-regulatoria/1.jpg')
  }
];

export const comitetecnicomultidisiplinario: { name: string, to: string }[] = [
  {
    name: 'Imagen',
    to: require('../assets/images/gallery/comite-tecnico-multidisiplinario/1.jpg')
  }
];


export const comitepremiosreconocimiento: { name: string, to: string }[] = [
  {
    name: 'Imagen',
    to: require('../assets/images/gallery/comite-premios-reconocimientos/1.jpg')
  }
];

export const comiteeticaconflicto: { name: string, to: string }[] = [
  {
    name: 'Imagen',
    to: require('../assets/images/gallery/comite-etica-conflicto-intereses/1.jpg')
  }
];

export const comisionauxiliarmixta: { name: string, to: string }[] = [
  {
    name: 'Imagen',
    to: require('../assets/images/gallery/comision-auxiliar-mixta-seguridad-higiene/1.jpg')
  }
];
