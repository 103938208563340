import { createMuiTheme } from '@material-ui/core/styles';
import { escala_gris } from './constants';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: escala_gris ? '#767576' : '#9d2449',
      contrastText: '#fff'
    },
    secondary: {
      main: '#333333',
      contrastText: '#fff'
    },
    text: {
      primary: '#333333'
    },
    background: {
      default: '#fff'
    }
  },
  typography: {
    h1: {
      fontWeight: 'bold',
      fontSize: 'calc(32px + 8 * (100vw - 0px) / 1650)'
    },
    h2: {
      fontWeight: 'bold',
      fontSize: 'calc(26px + 8 * (100vw - 0px) / 1650)'
    },
    h3: {
      fontWeight: 'bold',
      fontSize: 'calc(20px + 8 * (100vw - 0px) / 1650)'
    },
    h4: {
      fontWeight: 'bold',
      fontSize: 19
    },
    h5: {
      fontWeight: 'bold',
      fontSize: 17
    },
    h6: {
      fontWeight: 'bold',
      fontSize: 16
    }
  },
  overrides: {
    MuiButton: {
      containedPrimary: {
        boxShadow: escala_gris ? '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)' : '0px 3px 1px -2px rgb(157 36 73 / 20%), 0px 2px 2px 0px rgb(157 36 73 / 14%), 0px 1px 5px 0px rgb(157 36 73 / 12%)',
        '&:hover': {
          boxShadow: escala_gris ? '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)' : '0px 2px 4px -1px rgb(157 36 73 / 20%), 0px 4px 5px 0px rgb(157 36 73 / 14%), 0px 1px 10px 0px rgb(157 36 73 / 12%)'
        }
      }
    },
    MuiPaper: {
      elevation1: {
        boxShadow: '0px 7px 15px rgba(199, 199, 199, 0.45)'
      },
      elevation2: {
        boxShadow: '0px 10px 15px rgba(199, 199, 199, 0.50)'
      },
      elevation3: {
        boxShadow: '3px 10px 15px rgba(199, 199, 199, 0.55)'
      },
      elevation4: {
        boxShadow: '3px 13px 15px rgba(199, 199, 199, 0.60)'
      }
    }
  }
});

export default theme;