/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import muiRootWrapper from './mui-root-wrapper';
export const wrapRootElement = muiRootWrapper;

export const onInitialClientRender = () => {
  document.getElementById("___loader").style.display = "none"
  document.getElementById("___gatsby").style.display = "block";
}
