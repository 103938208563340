// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-congresoacademico-2021-index-tsx": () => import("./../../../src/pages/congresoacademico2021/index.tsx" /* webpackChunkName: "component---src-pages-congresoacademico-2021-index-tsx" */),
  "component---src-pages-congresoacademico-2021-programa-tsx": () => import("./../../../src/pages/congresoacademico2021/programa.tsx" /* webpackChunkName: "component---src-pages-congresoacademico-2021-programa-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-informacion-financiera-index-tsx": () => import("./../../../src/pages/informacion-financiera/index.tsx" /* webpackChunkName: "component---src-pages-informacion-financiera-index-tsx" */),
  "component---src-pages-licencias-tsx": () => import("./../../../src/pages/licencias.tsx" /* webpackChunkName: "component---src-pages-licencias-tsx" */),
  "component---src-pages-micita-tsx": () => import("./../../../src/pages/micita.tsx" /* webpackChunkName: "component---src-pages-micita-tsx" */),
  "component---src-pages-noticia-tsx": () => import("./../../../src/pages/noticia.tsx" /* webpackChunkName: "component---src-pages-noticia-tsx" */),
  "component---src-pages-proveedores-index-tsx": () => import("./../../../src/pages/proveedores/index.tsx" /* webpackChunkName: "component---src-pages-proveedores-index-tsx" */),
  "component---src-pages-servicios-enlinea-cuentas-bancarias-tsx": () => import("./../../../src/pages/servicios-enlinea/cuentas-bancarias.tsx" /* webpackChunkName: "component---src-pages-servicios-enlinea-cuentas-bancarias-tsx" */),
  "component---src-pages-servicios-enlinea-portal-creditos-tsx": () => import("./../../../src/pages/servicios-enlinea/portal-creditos.tsx" /* webpackChunkName: "component---src-pages-servicios-enlinea-portal-creditos-tsx" */),
  "component---src-pages-servicios-medicos-accidente-trabajo-tsx": () => import("./../../../src/pages/servicios-medicos/accidente-trabajo.tsx" /* webpackChunkName: "component---src-pages-servicios-medicos-accidente-trabajo-tsx" */),
  "component---src-pages-servicios-medicos-cem-tsx": () => import("./../../../src/pages/servicios-medicos/cem.tsx" /* webpackChunkName: "component---src-pages-servicios-medicos-cem-tsx" */),
  "component---src-pages-servicios-medicos-domicilios-umfs-tsx": () => import("./../../../src/pages/servicios-medicos/domicilios-umfs.tsx" /* webpackChunkName: "component---src-pages-servicios-medicos-domicilios-umfs-tsx" */),
  "component---src-pages-suggestions-mailbox-index-tsx": () => import("./../../../src/pages/suggestions-mailbox/index.tsx" /* webpackChunkName: "component---src-pages-suggestions-mailbox-index-tsx" */),
  "component---src-pages-suggestions-mailbox-personal-data-step-tsx": () => import("./../../../src/pages/suggestions-mailbox/PersonalDataStep.tsx" /* webpackChunkName: "component---src-pages-suggestions-mailbox-personal-data-step-tsx" */),
  "component---src-pages-suggestions-mailbox-suggestion-step-tsx": () => import("./../../../src/pages/suggestions-mailbox/SuggestionStep.tsx" /* webpackChunkName: "component---src-pages-suggestions-mailbox-suggestion-step-tsx" */),
  "component---src-pages-tramites-tsx": () => import("./../../../src/pages/tramites.tsx" /* webpackChunkName: "component---src-pages-tramites-tsx" */),
  "component---src-pages-transparencia-difusion-adquisiciones-presupuestos-tsx": () => import("./../../../src/pages/transparencia-difusion/adquisiciones-presupuestos.tsx" /* webpackChunkName: "component---src-pages-transparencia-difusion-adquisiciones-presupuestos-tsx" */),
  "component---src-pages-transparencia-difusion-bienes-tsx": () => import("./../../../src/pages/transparencia-difusion/bienes.tsx" /* webpackChunkName: "component---src-pages-transparencia-difusion-bienes-tsx" */),
  "component---src-pages-transparencia-difusion-calendario-pensiones-tsx": () => import("./../../../src/pages/transparencia-difusion/calendario-pensiones.tsx" /* webpackChunkName: "component---src-pages-transparencia-difusion-calendario-pensiones-tsx" */),
  "component---src-pages-transparencia-difusion-catalogo-cuadros-basicos-tsx": () => import("./../../../src/pages/transparencia-difusion/catalogo-cuadros-basicos.tsx" /* webpackChunkName: "component---src-pages-transparencia-difusion-catalogo-cuadros-basicos-tsx" */),
  "component---src-pages-transparencia-difusion-colaboracion-academicos-tsx": () => import("./../../../src/pages/transparencia-difusion/colaboracion-academicos.tsx" /* webpackChunkName: "component---src-pages-transparencia-difusion-colaboracion-academicos-tsx" */),
  "component---src-pages-transparencia-difusion-comite-comisiones-tsx": () => import("./../../../src/pages/transparencia-difusion/comite-comisiones.tsx" /* webpackChunkName: "component---src-pages-transparencia-difusion-comite-comisiones-tsx" */),
  "component---src-pages-transparencia-difusion-comites-tsx": () => import("./../../../src/pages/transparencia-difusion/comites.tsx" /* webpackChunkName: "component---src-pages-transparencia-difusion-comites-tsx" */),
  "component---src-pages-transparencia-difusion-cuenta-publica-tsx": () => import("./../../../src/pages/transparencia-difusion/cuenta-publica.tsx" /* webpackChunkName: "component---src-pages-transparencia-difusion-cuenta-publica-tsx" */),
  "component---src-pages-transparencia-difusion-evaluacion-diseno-programa-tsx": () => import("./../../../src/pages/transparencia-difusion/evaluacion-diseño-programa.tsx" /* webpackChunkName: "component---src-pages-transparencia-difusion-evaluacion-diseno-programa-tsx" */),
  "component---src-pages-transparencia-difusion-informe-trimestral-indicadores-tsx": () => import("./../../../src/pages/transparencia-difusion/informe-trimestral-indicadores.tsx" /* webpackChunkName: "component---src-pages-transparencia-difusion-informe-trimestral-indicadores-tsx" */),
  "component---src-pages-transparencia-difusion-informes-tsx": () => import("./../../../src/pages/transparencia-difusion/informes.tsx" /* webpackChunkName: "component---src-pages-transparencia-difusion-informes-tsx" */),
  "component---src-pages-transparencia-difusion-lineamiento-criterio-tsx": () => import("./../../../src/pages/transparencia-difusion/lineamiento-criterio.tsx" /* webpackChunkName: "component---src-pages-transparencia-difusion-lineamiento-criterio-tsx" */),
  "component---src-pages-transparencia-difusion-list-comite-tsx": () => import("./../../../src/pages/transparencia-difusion/list-comite.tsx" /* webpackChunkName: "component---src-pages-transparencia-difusion-list-comite-tsx" */),
  "component---src-pages-transparencia-difusion-pasivo-historico-tsx": () => import("./../../../src/pages/transparencia-difusion/pasivo-historico.tsx" /* webpackChunkName: "component---src-pages-transparencia-difusion-pasivo-historico-tsx" */),
  "component---src-pages-transparencia-difusion-planeacion-tsx": () => import("./../../../src/pages/transparencia-difusion/planeacion.tsx" /* webpackChunkName: "component---src-pages-transparencia-difusion-planeacion-tsx" */),
  "component---src-pages-transparencia-difusion-programa-agenda-mejora-regulatoria-tsx": () => import("./../../../src/pages/transparencia-difusion/programa-agenda-mejora-regulatoria.tsx" /* webpackChunkName: "component---src-pages-transparencia-difusion-programa-agenda-mejora-regulatoria-tsx" */),
  "component---src-pages-transparencia-difusion-sevac-tsx": () => import("./../../../src/pages/transparencia-difusion/sevac.tsx" /* webpackChunkName: "component---src-pages-transparencia-difusion-sevac-tsx" */),
  "component---src-pages-transparencia-difusion-sia-tsx": () => import("./../../../src/pages/transparencia-difusion/sia.tsx" /* webpackChunkName: "component---src-pages-transparencia-difusion-sia-tsx" */),
  "component---src-pages-tu-instituto-comite-tsx": () => import("./../../../src/pages/tu-instituto/comite.tsx" /* webpackChunkName: "component---src-pages-tu-instituto-comite-tsx" */),
  "component---src-pages-tu-instituto-contacto-tsx": () => import("./../../../src/pages/tu-instituto/contacto.tsx" /* webpackChunkName: "component---src-pages-tu-instituto-contacto-tsx" */),
  "component---src-pages-tu-instituto-estructura-organica-tsx": () => import("./../../../src/pages/tu-instituto/estructura-organica.tsx" /* webpackChunkName: "component---src-pages-tu-instituto-estructura-organica-tsx" */),
  "component---src-pages-tu-instituto-faq-tsx": () => import("./../../../src/pages/tu-instituto/faq.tsx" /* webpackChunkName: "component---src-pages-tu-instituto-faq-tsx" */),
  "component---src-pages-tu-instituto-galeria-tsx": () => import("./../../../src/pages/tu-instituto/galeria.tsx" /* webpackChunkName: "component---src-pages-tu-instituto-galeria-tsx" */),
  "component---src-pages-tu-instituto-historia-tsx": () => import("./../../../src/pages/tu-instituto/historia.tsx" /* webpackChunkName: "component---src-pages-tu-instituto-historia-tsx" */),
  "component---src-pages-tu-instituto-marco-juridico-tsx": () => import("./../../../src/pages/tu-instituto/marco-juridico.tsx" /* webpackChunkName: "component---src-pages-tu-instituto-marco-juridico-tsx" */),
  "component---src-pages-tu-instituto-mensaje-tsx": () => import("./../../../src/pages/tu-instituto/mensaje.tsx" /* webpackChunkName: "component---src-pages-tu-instituto-mensaje-tsx" */),
  "component---src-pages-tu-instituto-mision-tsx": () => import("./../../../src/pages/tu-instituto/mision.tsx" /* webpackChunkName: "component---src-pages-tu-instituto-mision-tsx" */),
  "component---src-pages-tu-instituto-organos-colegiados-tsx": () => import("./../../../src/pages/tu-instituto/organos-colegiados.tsx" /* webpackChunkName: "component---src-pages-tu-instituto-organos-colegiados-tsx" */),
  "component---src-pages-tu-instituto-politica-privacidad-tsx": () => import("./../../../src/pages/tu-instituto/politica-privacidad.tsx" /* webpackChunkName: "component---src-pages-tu-instituto-politica-privacidad-tsx" */),
  "component---src-pages-tu-instituto-servicios-tsx": () => import("./../../../src/pages/tu-instituto/servicios.tsx" /* webpackChunkName: "component---src-pages-tu-instituto-servicios-tsx" */)
}

